const TYPE_DOCDOC_IDS = {
  DOCDOC_NOT_FOUND: 0, // 0 - не найден у нас
  DOCDOC_FOUND_ONE: 1, // 1 - найден ровно 1 врачь
  DOCDOC_MORE_ONE_NOT_SIMILAR_SPECIALITY: 2, // 2 - найдено > 1 врача (похожие) НЕТУ схожести по специальности
  DOCDOC_MORE_ONE_YEAP_SIMILAR_SPECIALITY: 3, // 3 - найдено > 1 врача (похожие) ЕСТЬ схожесть со специальностью (slug || name)
  DOCDOC_FOUND_ONE_WITH_INITIALS: 4, // 4 - найден ровно 1 по инициалам (Дуева В. М. || Дуева В М)
  DOCDOC_MORE_ONE_NOT_SIMILAR_SPECIALITY_WITH_INITIALS: 5, // 5 - найдено > 1 по инициалам (Дуева В. М. || Дуева В М) (похожие) НЕТУ схожести по специальности
  DOCDOC_MORE_ONE_YEAP_SIMILAR_SPECIALITY_WITH_INITIALS: 6, // 6 - найдено > 1 по инициалам (Дуева В. М. || Дуева В М) (похожие) ЕСТЬ схожесть со специальностью (slug || name)
  DOCDOC_ALL_CITIES: 7, // 7 - поиск по всем городам
}

const TYPE_DOCDOC_LIST = [
  { id: TYPE_DOCDOC_IDS.DOCDOC_NOT_FOUND, value: 'Не найден у нас' },
  { id: TYPE_DOCDOC_IDS.DOCDOC_FOUND_ONE, value: '1 - точ. совпадение по ФИО' },
  { id: TYPE_DOCDOC_IDS.DOCDOC_MORE_ONE_NOT_SIMILAR_SPECIALITY, value: '2 - найдено более 1 врача, совпадения по специальности НЕТ' },
  { id: TYPE_DOCDOC_IDS.DOCDOC_MORE_ONE_YEAP_SIMILAR_SPECIALITY, value: '3 - найдено более 1 врача, совпадение по специальности ЕСТЬ' },
  { id: TYPE_DOCDOC_IDS.DOCDOC_FOUND_ONE_WITH_INITIALS, value: '4 - точ. совпадение по фамилии и инициалам (Дуева В. М.)' },
  { id: TYPE_DOCDOC_IDS.DOCDOC_MORE_ONE_NOT_SIMILAR_SPECIALITY_WITH_INITIALS, value: '5 - найдено более 1 врача по фамилии и инициалам, совпадения по специальности НЕТ' },
  { id: TYPE_DOCDOC_IDS.DOCDOC_MORE_ONE_YEAP_SIMILAR_SPECIALITY_WITH_INITIALS, value: '6 - найдено более 1 врача по фамилии и инициалам, совпадение по специальности ЕСТЬ' },
  { id: TYPE_DOCDOC_IDS.DOCDOC_ALL_CITIES, value: '7 - поиск по всем городам' },
]

const STATUS_DOCTORS_IDS = {
  SHOW_STATUS_HIDE: 0,
  SHOW_STATUS_HIDE_BY_ALERT: 1,
  SHOW_STATUS_VISIBLE: 2,
  SHOW_STATUS_MERGED: 3,
}

const STATUS_DOCTORS_LIST = [
  { id: STATUS_DOCTORS_IDS.SHOW_STATUS_VISIBLE, value: 'Показывается' },
  { id: STATUS_DOCTORS_IDS.SHOW_STATUS_HIDE_BY_ALERT, value: 'Скрыт по просьбе врача' },
  { id: STATUS_DOCTORS_IDS.SHOW_STATUS_HIDE, value: 'Скрыт' },
  { id: STATUS_DOCTORS_IDS.SHOW_STATUS_MERGED, value: 'Скрыт, так как объединен' },
]

const GENDER_IDS = {
  GENDER_FEMALE: 0,
  GENDER_MALE: 1,
}

const GENDER_LIST = [
  { id: GENDER_IDS.GENDER_MALE, value: 'Мужчина' },
  { id: GENDER_IDS.GENDER_FEMALE, value: 'Женщина' },
]

const CATEGORY_IDS = {
  CATEGORY_FIRST: 0,
  CATEGORY_SECOND: 1,
  CATEGORY_HIGH: 2,
}

const CATEGORY_LIST = [
  { id: CATEGORY_IDS.CATEGORY_FIRST, value: 'Первая' },
  { id: CATEGORY_IDS.CATEGORY_SECOND, value: 'Вторая' },
  { id: CATEGORY_IDS.CATEGORY_HIGH, value: 'Высшая' },
]

const DEGREE_IDS = {
  DEGREE_CANDIDAT: 0,
  DEGREE_DOCTOR: 1,
  DEGREE_PROFESSOR: 2,
}

const DEGREE_LIST = [
  { id: DEGREE_IDS.DEGREE_CANDIDAT, value: 'Кандидат наук' },
  { id: DEGREE_IDS.DEGREE_DOCTOR, value: 'Доктор наук' },
  { id: DEGREE_IDS.DEGREE_PROFESSOR, value: 'Профессор' },
]

const TYPE_IDS = {
  TYPE_ADULT: 0,
  TYPE_CHILDREN: 1,
  TYPE_DANTIST: 2,
  TYPE_ADULT_AND_CHILDREN: 3,
}

const TYPE_LIST = [
  { id: TYPE_IDS.TYPE_ADULT, value: 'Взрослый' },
  { id: TYPE_IDS.TYPE_CHILDREN, value: 'Детский' },
  { id: TYPE_IDS.TYPE_DANTIST, value: 'Зубной' },
  { id: TYPE_IDS.TYPE_ADULT_AND_CHILDREN, value: 'Взрослый и детский' },
]

export default {
  // Статус доктора
  TYPE_DOCDOC_IDS,
  TYPE_DOCDOC_LIST,

  // Статус доктора
  STATUS_DOCTORS_IDS,
  STATUS_DOCTORS_LIST,
  GENDER_IDS,
  GENDER_LIST,

  // Категория врача
  CATEGORY_IDS,
  CATEGORY_LIST,

  // Степень врача
  DEGREE_IDS,
  DEGREE_LIST,

  // Тип врача
  TYPE_IDS,
  TYPE_LIST,
}
