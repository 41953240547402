<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      :opacity=".8"
    >
      <div class="d-flex justify-content-end">
        <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          class="mr-1"
          right
          no-flip
          variant="secondary"
          text="Фильтр"
        >
          <b-dropdown-form
            class="py-1"
            @submit.prevent
          >
            <b-form-group
              label="По ФИО:"
              label-for="dropdown-form-name"
              @submit.prevent.stop
            >
              <b-form-input
                id="dropdown-form-name"
                v-model="filters.name"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              label="Город:"
              label-for="city_id"
            >
              <v-select
                v-model="filters.city_id"
                label="name"
                :reduce="option => option.id"
                :options="citiesList"
              />
            </b-form-group>

            <b-form-group
              label="Статус"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filters.status"
                size="sm"
                value-field="id"
                text-field="value"
                :options="[{id: null, value: '----------'}, ...DoctorsConstants.STATUS_DOCTORS_LIST]"
              />
            </b-form-group>

            <b-form-group
              label="Кол-во на странице"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filters.perPage"
                size="sm"
                :options="[10, 20, 50, 100, 500]"
              />
            </b-form-group>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="doApplyFilters"
              >
                Применить
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="doResetFilters"
              >
                Сбросить
              </b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown>
        <router-link :to="{name: 'doctors-create'}">
          <b-button variant="primary">
            Создать
          </b-button>
        </router-link>
      </div>

      <data-table
        :columns="columns"
        :rows.sync="rows"
        :settings="settings"
        :dev-logs="false"
        @updateFilter="doUpdateFilter"
      >

        <template
          slot="table-column"
          slot-scope="props"
        >
          <div class="text-center">
            {{ props.column.label }}
          </div>
        </template>

        <template v-slot:table-row="item">
          <div
            v-if="item.column.field === 'full_name'"
            class="text-center"
          >
            <router-link :to="{name: 'doctors-update', params: {id: item.row.id}}">
              {{ item.row.full_name }}
            </router-link>
          </div>
          <div
            v-else-if="item.column.field === 'is_show'"
            class="text-center"
          >
            <span
              v-if="item.row.is_show === DoctorsConstants.STATUS_DOCTORS_IDS.SHOW_STATUS_HIDE"
              class="text-danger"
            >{{ getStatusDoctor(item.row.is_show) }}</span>
            <span
              v-if="item.row.is_show === DoctorsConstants.STATUS_DOCTORS_IDS.SHOW_STATUS_HIDE_BY_ALERT"
              class="text-warning"
            >{{ getStatusDoctor(item.row.is_show) }}</span>
            <span
              v-if="item.row.is_show === DoctorsConstants.STATUS_DOCTORS_IDS.SHOW_STATUS_VISIBLE"
              class="text-success"
            >{{ getStatusDoctor(item.row.is_show) }}</span>
          </div>
          <div
            v-else-if="item.column.field === 'action'"
            class="text-center"
          >
            <router-link :to="{name: 'doctors-update', params: {id: item.row.id}}">
              <b-button
                variant="flat-warning"
                class="btn-icon"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="text-warning"
                />
              </b-button>
            </router-link>

            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="objectDelete(item.row.id)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="text-danger"
              />
            </b-button>
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ item.formattedRow[item.column.field] }}
          </div>
        </template>
      </data-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BDropdown,
  BDropdownForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import DataTable from '@/components/DataTable.vue'
import axiosIns from '@/libs/axios'
import { rowsObjectConverter, settingsObjectConverter } from '@/helpers/dataTableHelper'
import dataTableMixin from '@/mixins/dataTableMixin'
import DoctorsConstants from '@/consts/doctors'

export default {
  name: 'DoctorsList',
  components: {
    BCard,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BButton,
    BButtonGroup,
    BFormSelect,
    BOverlay,
    BFormInput,
    DataTable,
    vSelect,
  },
  mixins: [
    dataTableMixin,
  ],
  metaInfo: {
    title: 'Список врачей',
  },
  data() {
    return {
      loading: true,
      filterOnURL: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Ф.И.О.',
          field: 'full_name',
        },
        {
          label: 'Город',
          field: rowObj => (rowObj.city_id !== null && rowObj.city_id ? this.getCityName(rowObj.city_id) : '—'),
        },
        {
          label: 'Статус',
          field: 'is_show',
        },
        {
          label: 'Действия',
          field: 'action',
        },
      ],
      citiesList: [],
      DoctorsConstants,
    }
  },
  beforeMount() {
    Promise.all([
      this.doLoadCities(),
    ]).then(() => {
      Promise.all([
        this.doLoadDoctors(),
      ]).then(() => {
        this.loading = false
      })
    })

    // Пушим промисы фильтров, чтоб вызывать методы
    this.filterPromises.push(() => this.doLoadDoctors())
  },
  methods: {
    async doLoadDoctors() {
      const res = await axiosIns({
        method: 'GET',
        url: '/doctors/list',
        params: _.merge(this.filters),
      })

      this.$set(this, 'settings', settingsObjectConverter(res.data.data))
      this.$set(this, 'rows', rowsObjectConverter(res.data.data))

      return res
    },
    async doLoadCities() {
      const res = await axiosIns({
        method: 'GET',
        url: '/cities/options',
      })

      this.citiesList = res.data.data

      return res
    },
    getCityName(cityId) {
      // eslint-disable-next-line radix
      return _.find(this.citiesList, city => city.id === parseInt(cityId)).name ?? '—'
    },
    getStatusDoctor(id) {
      // eslint-disable-next-line radix
      return _.find(DoctorsConstants.STATUS_DOCTORS_LIST, o => o.id === parseInt(id)).value ?? '—'
    },
    async objectDelete(id) {
      const modalResult = await this.$swal({
        title: '',
        text: 'Вы уверены что хотите удалить эту запись?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (modalResult.value) {
        await axiosIns({
          method: 'DELETE',
          url: `/doctors/${id}/delete`,
        })

        await this.doLoadDoctors()
        this.$swal({
          icon: 'success',
          title: 'Удалено!',
          text: '',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
